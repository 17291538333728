import jwtDecode from "jwt-decode";

// **  Initial State
const initialState = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    id: '',
    first_name: '',
    last_name: '',
    mobile: '',
    roles: [],
    permissions: []
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            let user;
            try {
                user = jwtDecode(action.token);
                return {
                    ...initialState,
                    id: user.id,
                    token: user.token,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    mobile: user.mobile,
                    roles: user.roles
                };
            } catch (e) {
                localStorage.removeItem("token");
                return state;
            }
        case 'LOGOUT':
            const obj = {...action}
            delete obj.type
            let tmp = initialState;
            tmp.token = '';
            localStorage.removeItem("token")
            return {...state, ...tmp}
        default:
            return state
    }
}

export default authReducer
