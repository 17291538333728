const initialState = {
    articles: []
}

const UIReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UI':
            return {
                ...state,
                articleCategories: action.data ? action.data.article_categories : [],
                latestArticles: action.data ? action.data.latest_articles : [],
                mostVisitedArticles: action.data ? action.data.most_visited_articles : [],
                questions: action.data ? action.data.questions : [],
                news: action.data ? action.data.notice : null,
                video: action.data ? action.data.video : null,
            }
        default:
            return state
    }
}

export default UIReducer
