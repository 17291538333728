//social media
import {Mail, PhoneCall, Instagram} from "react-feather";
/*import eitaaIcon from '@assets/images/icons/eitaa.png';
import soroushIcon from '@assets/images/icons/soroush.png';*/

export const DETAILS = {
    "name": "شهرک الهیه",
    "enName": "shahrak elahiye",
    "description": "",
    "mobile": [],
    "phones": [
        {
            label: 'نگهبانی',
            value: '02634707176'
        },
        {
            label: 'حسابداری',
            value: '02634700089'
        },
        {
            label: 'مدیریت',
            value: '09126028546'
        },
        {
            label: 'فوریت های گاز',
            value: '09125681506'
        },
        {
            label: 'آبرسانی',
            value: '09126657633'
        },
    ],
    "email": [],
    "postalCode": [""],
    address: 'بعد از میدان بهشتی کمال شهر به سمت هشتگرد، نرسیده به پلیس راه کرج-هشتگرد جنوب، جاده شهرک الهیه، دفتر شهرک',
    mapIframeSrc: "",
    socialMedia: [
        {
            title: 'واتسپ',
            value: '',
            name: 'whatsapp',
            link: '',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },
        {
            title: 'تلگرام',
            value: '',
            name: 'telegram',
            link: '',
            icon: <i className="lab la-telegram-plane"/>,
            isPrimary: true
        },
        {
            title: 'اینستاگرام',
            value: '',
            name: 'instagram',
            link: '',
            icon: <Instagram/>,
            isPrimary: true
        },
        {
            title: 'تماس',
            value: '',
            name: 'phone',
            link: '',
            icon: <PhoneCall/>,
            isPrimary: true
        },
        {
            title: 'ایمیل',
            value: '',
            name: 'email',
            link: '',
            icon: <Mail/>,
            isPrimary: true
        },
        {
            title: 'تماس',
            value: '',
            name: 'phone',
            link: '',
            icon: <PhoneCall/>
        }
    ],
    emergencyContact:{
        gas: '09125681506'
    },
    "supportDescription": "",
    "workingHours": [],
    "__choose among these__": "",
    "enamadLink": "",
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
